import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './Purchase.scss';

function Purchase() {
  const [t] = useTranslation();

  return (
    <div id="purchase" className="purchase">
      <img
        className="purchase-image"
        src="./assets/purchase/decal.png"
        alt="Decal"
      />
      <div className="purchase-container">
        <motion.div
          className="purchase-title"
          initial={{ transform: 'translate(0, 50%)', opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            duration: 0.7,
            delay: 0.5,
          }}
          viewport={{ once: true }}
          whileInView={{ transform: 'translate(0, 0)', opacity: 1 }}
        >
          <img src="./assets/purchase/station.png" className="purchase-title-logo" />
        </motion.div>

        <div className="purchase-description">{t('purchase.description')}</div>

        <motion.button
          className="purchase-button"
          onClick={() => document.getElementById('purchase-modal').showModal()}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <div>{t('purchase.button')}</div>
        </motion.button>
      </div>
    </div>
  );
}

export default Purchase;
