import { useTranslation } from 'react-i18next';
import './Footer.scss';

function Footer() {
  const [t] = useTranslation();

  return (
    <footer className="footer footer-center text-primary-content p-10">
      <aside>
        <img className="logo" src="./assets/logo.png" alt="Soulart Logo" />
        <p className="font-bold">{t('footer.comp')}</p>
        <p>{t('footer.representative')}</p>
        <p>{t('footer.rn')}</p>
        <p>{t('footer.address')}</p>
      </aside>
    </footer>
  );
}

export default Footer;
